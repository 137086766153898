import "./summary.css";
import { IconHeader } from "../icon-header/iconHeader";

export const Summary = ({ title, icon, text }) => {
  return (
    <div className="sf-summary-holder">
      <IconHeader icon={icon} title={title}/>
      <div className="small-segment-holder">
        <span className="text">
          <p>{text}</p>
        </span>
      </div>
    </div>
  );
};
