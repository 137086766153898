import "./App.css";
import { Header } from "./component/header/header";
import { Summary } from "./component/summary/summary";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { SplitBody } from "./component/split-body/splitBody";
import { Project } from "./component/project/project";

const cvContent = {
  summary: `Developer, Data Analyst and Petroleum Engineer with 5 years of experiences focus on building highly functional applications and tools for organizations to automate data extraction and create advance data analysis capabilities to gather insightful information regarding to daily operation of oil fields in Malaysia and other countries. Moreover, he is also capable of developing highly secure and high performance back-end, front-end, mobile and desktop applications required by organization. Person who posses with curiosity and passion in facing new challenge with objective to tackle organization’s problem with fresh ideas/solutions based on multidisciplinary experiences.`,
  experience: [
    {
      header: "Techo Startup Center (Cambodia)",
      rightHeader: "2020 - Present",
      title: "Blockchain/Software Developer",
      description:
        "Led team of developers researching, developing, operating, and maintaining CamDL (Cambodia Distributed Ledger) hybrid permissioning, EVM compatible blockchain network powered by Hyperledger Besu. Develop highly scalable and secure back-end applications by using monolithic/micro-services architecture with various frameworks such as Java Spring Boot, NodeJS, and Go. In addition, he created/manage CI/CD pipeline and manage kubernetes cluster including application deployment and cluster monitoring. Besides that, also work as UX/UI designer for multiple projects under the Cambodia Data Exchange Platform (CamDX) and CamDigiKey.",
    },
    {
      header: "Three60 Energy (Malaysia)",
      rightHeader: "2017 - 2020",
      title: "Reservoir Engineering / Software Developer",
      description:
        "Responsibilities include Poseidon™ development focus mainly on deterministic & stochastic production allocation, locating the remaining oil, production forecasting and production data analysis. Led group of developers to develop Titan™ - Oil Production Surveillance software (Automation, Data processing, Data analytics, Reporting)",
    },
    {
      header: "Emerald Resources (Cambodia)",
      rightHeader: "2015",
      title: "Field Geologist / Geophysicists",
      description:
        "Task with field data sampling, assisting exploration drilling activities, study/analysis on existing gravity/magnetic survey map, produce detail map over prospected gold deposited area with detail geological interpretation and predicting pattern of gold vein for future exploration activities.",
    },
  ],
  project: [
    {
      header: "CamDL (Cambodia Distributed Ledger)",
      rightHeader: "2022",
      href: "https://camdl.gov.kh",
      title: "Project Lead - DevOps - Smart Contract Developer",
      description: `CamDL is a hybrid permission EVM-compatible blockchain network that aims to provide the public with the platform to test, experiment, learn, and develop Web 3.0 applications, decentralized applications (DApp), and decentralized finance (DeFi) to solve everyday challenges in socio-economics. CamDL was launched on November 2022. In this project, he led various teams in researching regulatory compliance, technical specifications of various blockchain technologies, and planning and executing. Now, he is responsible for overseeing the operation, blockchain/cluster nodes operator, technical, future innovation, and regulatory compliance aspects of the network.`,
    },
    {
      header: "CamDX Open KYC APIs",
      href: "https://camdigikey.gov.kh/businesses",
      rightHeader: "2022",
      title: "Backend Developer - DevOps",
      description:
        "With the success of CamDigiKey regarding user KYC and information verification, a collection of API provides to various financial institutions and insurance companies through microservices application for them to improve their KYC process as part of regulatory compliance. This is currently being used by more than 20 financial institutions and insurance companies. A complete DevOps pipeline has also been created. Automate build, test, deploy, monitor, and health check notification with the deployment on highly scalable with zero downtime Kubernetes cluster are also implemented.",
    },
    {
      header: "CamDigiKey",
      href: "https://camdigikey.gov.kh/",
      rightHeader: "2021 - 2022",
      title: "Backend Developer",
      description:
        "Developing and maintaining highly secure and highly scalable microservices applications ensures a good user experience with low latency and zero downtime application. CamDigiKey is a mobile KYC (Know your customer) and mobile authentication system for users to be able to login into various web portals especially online business registration services and other public or private portals. CamDigiKey was designed and built with high security, throughput, and zero downtime as it is the core to all other platforms that rely on it.",
    },
    {
      header: "Ministry of Tourism Registration Portal",
      href: "https://mot.registrationservices.gov.kh/",
      rightHeader: "2020",
      title: "UI/UX - Developer",
      description:
        "Involve with team communicating with officers from various ministry, understanding their requirement, designing modern easy-to-use UI, and building backend services for the general user to be able to register, pay the fee through online payment, and obtains a business license in tourism. This portal is one among plenty of online business registration services created by the government of Cambodia as part of digital government transformation to improve efficiency, easy for startups or investors to register their businesses and reduce corruption along the process",
    },
    {
      header: "Titan™",
      rightHeader: "2019 - 2020",
      href: "https://www.linkedin.com/posts/three60energy_betterenergytogether-coveringalltheangles-activity-6962743962373648384-yG5L?utm_source=share&utm_medium=member_desktop",
      title: "Developer / Project Lead",
      description:
        "After he observed the inefficiency of manual work by engineers regarding oil field production management and monitoring, led to the proposal of the software. Titan™ automates the workflow of engineers instead of spending hours of data importing, processing, analyzing, troubleshooting, and report generating to just a few minutes through the use of the software.",
    },
    {
      header: "Poseidon™",
      rightHeader: "2017 - 2019",
      href: "https://www.linkedin.com/posts/three60energy_betterenergytogether-coveringalltheangles-activity-6962743962373648384-yG5L?utm_source=share&utm_medium=member_desktop",
      title: "Developer",
      description:
        "Contribute to the process of building Poseidon™, a desktop application that is used by Petroleum Engineers to manage, analyze and predict oil fields. Features include reservoir management, production surveillance, production allocation, advance analytics, locating remaining oil, and predict remaining reserves for mature oil field",
    },
  ],
  education: [
    {
      header: "University of Puthisastra (Cambodia)",
      rightHeader: "2021 - 2023",
      title: "Master of Science in IT - Data Science",
    },
    {
      header: "Chulalongkorn University (Thailand)",
      rightHeader: "2015 - 2017",
      title: "Master of Petroleum Engineer",
    },
    {
      header: "Institute of Technology of Cambodia",
      rightHeader: "2010 - 2015",
      title: "Georesources and Geotechnical Engineering",
    },
  ],
  skill: [
    {
      name: "Blockchain",
      score: 5,
    },
    {
      name: "Backend Development",
      score: 5,
    },
    {
      name: "DevOps",
      score: 5,
    },
    {
      name: "Frontend Development",
      score: 4,
    },
    {
      name: "Desktop Development",
      score: 5,
    },
    {
      name: "Mobile Development",
      score: 4,
    },
    {
      name: "Data Analyst",
      score: 4,
    },
  ],
  itSkill: [
    {
      header: "Programming Language",
      title: "Java, C#, Python, Javascript, Bash, Go, Solidity",
    },
    {
      header: "Backend Development",
      title:
        "Spring Boot, Asp.Net, Asp.Net Core, NodeJS, fasthttp, gin-gonic, SQL, NoSQL",
    },
    {
      header: "DevOps",
      title:
        "AWS, Kubernetes, Nginx, Jenkins, Ansible, Docker, Uptime Kuma, Prometheus, Loki, Grafana, ArgoCD",
    },
    {
      header: "Frontend Development",
      title: "Angular, React",
    },
    {
      header: "Mobile Development",
      title: "React Native, Flutter, Xamarin Forms",
    },
    {
      header: "Desktop Development",
      title: "WPF, Electron",
    },
  ],
  language: [
    {
      header: "Khmer",
      rightHeader: "Native",
    },
    {
      header: "English",
      rightHeader: "Fluent",
    },
  ],
};
function App() {
  return (
    <div className="sf-wrapper no-pjs-render">
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      ></link>
      <Header></Header>
      <div className="sf-summary-holder sf-content-holder sf-bottom-margin">
        <Summary
          title={"Summary"}
          icon={faCheck}
          text={cvContent.summary}
        ></Summary>
        <SplitBody cvContent={cvContent}></SplitBody>
        <Project projects={cvContent.project}></Project>
      </div>
    </div>
  );
}

export default App;
