import { IconHeader } from "../icon-header/iconHeader";
import { InfoCard } from "../info-card/infoCard";
import { ScoreCard } from "../score-card/scoreCard";
import "./splitBody.css";
import {
  faBriefcase,
  faGraduationCap,
  faPlus,
  faStar,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";

function populateCards(arrays) {
  let result = [];
  for (let i = 0; i < arrays.length; i++) {
    const element = arrays[i];
    result.push(
      <InfoCard
        location={element.header}
        year={element.rightHeader}
        title={element.title}
        description={element.description}
      />
    );
  }
  return result;
}

function populateScores(arrays) {
  let result = [];
  for (let i = 0; i < arrays.length; i++) {
    const element = arrays[i];
    result.push(
      <ScoreCard title={element.name} score={element.score}></ScoreCard>
    );
  }
  return result;
}

export const SplitBody = ({ cvContent }) => {
  return (
    <div class="sf-main-holder sf-body-holder">
      <div class="sf-main-left">
        <div class="sf-main-box-holder-left">
          <IconHeader icon={faBriefcase} title={"Work experience"} />
          {populateCards(cvContent.experience)}
          <IconHeader icon={faGraduationCap} title={"Education"} />
          {populateCards(cvContent.education)}
        </div>
      </div>
      <div class="sf-main-right">
        <div class="sf-main-box-holder-right">
          <IconHeader icon={faStar} title={"Skill"} />
          {populateScores(cvContent.skill)}
          <IconHeader icon={faPlus} title={"IT Skills"} />
          {populateCards(cvContent.itSkill)}
          <IconHeader icon={faLanguage} title={"Language"} />
          {populateCards(cvContent.language)}
        </div>
      </div>
    </div>
  );
};
