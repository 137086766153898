import './scoreCard.css'
export const ScoreCard = ({ title, score }) => {
    const generateStar = (score) =>{
        let stars = []
        for (let i = 0; i < 5; i++) {
            if (i < score){
                stars.push(<div class="circle"></div>)
            }else{
                stars.push(<div class="circle-nofill"></div>)
            }         
        }
        return stars
    }
  return (
    <div class="small-segment-skill-holder ">
      <div class="sf-main-holder">
        <div class="sf-half-right">
          <span class="namn">{title}</span>
        </div>
        <div class="sf-half-left right-align">
          {generateStar(score)}
        </div>
      </div>
    </div>
  );
};
