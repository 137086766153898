import "./infoCard.css";

export const InfoCard = ({ year, location, title, description, link }) => {
  return (
    <div class="small-segment-holder">
      {year !== "" ? <span class="datum">{year}</span> : ""}
      {link === "" ? (
        <span class="namn">{location}</span>
      ) : (
        <span class="namn">
          <a target="_blank"
              rel="noreferrer" href={link}>{location}</a>{" "}
        </span>
      )}
      {title !== "" ? <span class="titel">{title}</span> : ""}
      {description !== "" ? (
        <span class="text">
          <p className="p-padding">{description}</p>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};
