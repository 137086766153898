import "./iconHeader.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IconHeader = ({icon,title}) => {
  return (
    <div className="sf-heading-holder">
      <div className="sf-fa-holder">
        <FontAwesomeIcon className="tt-title-icon" icon={icon} size="l" />
      </div>
      <span className="sf-main-title">{title}</span>
    </div>
  );
};
