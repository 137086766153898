import { Component } from "react";
import portrait from "../../assets/vathana.jpg";
import bg from "../../assets/bg.jpg";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const styles = {
  bgPicture: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
};

class Header extends Component {
  render() {
    return (
      <div className="sf-holder">
        <div className="sf-top-first" style={styles.bgPicture}>
          <div className="sf-top-social">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/tum.sereyvathana"
            >
              <FontAwesomeIcon className="social-icon" icon={faFacebook} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/tum_sereyvathana/"
            >
              <FontAwesomeIcon className="social-icon" icon={faInstagram} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/serey-vathana-tum-5b893469/"
            >
              <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
            </a>
          </div>

          <div className="cvm-avatar">
            <img
              src={portrait}
              alt="Portrait of Serey Vathana Tum attending Cambodia Tech Expo 2022"
            />
          </div>
        </div>

        <div className="sf-top-second">
          <span className="sf-name">Tum Serey Vathana </span>
          <span className="sf-title">
            Software/Blockchain Developer, Data Analyst & Petroleum Engineer
          </span>
        </div>

        <div class="sf-content-holder">
          <div class="sf-contact-holder">
            <div class="sf-contact">
              <span class="namn namn-bottom-pad">E-mail </span>
              <a
                class="text-description"
                href="mailto:sereyvathanatum@gmail.com"
              >
                sereyvathanatum@gmail.com
              </a>
            </div>
            <div class="sf-contact">
              <span class="namn namn-bottom-pad">Phone </span>
              <a class="text-description" href="tel:+85578887007">
                +855 78 887007
              </a>
            </div>
            <div class="sf-contact">
              <span class="namn namn-bottom-pad">Address </span>
              <span class="text">
                52Lo st.265 Teuk Laok III
                <br />
                Toul Kork Phnom Penh
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Header };
