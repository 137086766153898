import { IconHeader } from "../icon-header/iconHeader";
import { InfoCard } from "../info-card/infoCard";
import "./project.css";
import {
  faListOl,
} from "@fortawesome/free-solid-svg-icons";

export const Project = ({ projects }) => {
  let leftCards = [];
  let rightCards = [];
  for (let i = 0; i < projects.length; i++) {
    const element = projects[i];
    if (i % 2 === 0) {
      leftCards.push(
        <InfoCard
          location={element.header}
          year={element.rightHeader}
          title={element.title}
          link={element.href}
          description={element.description}
        />
      );
    } else {
      rightCards.push(
        <InfoCard
          location={element.header}
          year={element.rightHeader}
          title={element.title}
          link={element.href}
          description={element.description}
        />
      );
    }
  }
  return (
    <div className="sf-summary-holder sf-project-holder">
      <IconHeader icon={faListOl} title={"Projects"} />
      <div class="sf-main-left">
        <div class="sf-main-box-holder-left">{leftCards}</div>
      </div>
      <div class="sf-main-right">
        <div class="sf-main-box-holder-right">{rightCards}</div>
      </div>
    </div>
  );
};
